import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
// import GridLines from "../components/gridlines"
// import Timeline from "../components/timeline"
// import { Link } from "gatsby"
import GridLines from "../components/gridlines"
import FormCheck from "../components/form-check"
import Row from "../components/bootstrap/row"
import PartnerCard from "../components/partner-card"
import { OutboundLink } from "gatsby-plugin-google-analytics"
// import ImgCipherLabLogo from "../components/img-cipherlab-logo"
import LakesideSoftwareLogoImage from "../components/lakeside-software-logo-image"
import Packages from "../components/packages"

// import PlacesBackgroundImage from "../components/places-background-image"
import BandsCircle from "../components/bands-circle"
import BandsDiamond from "../components/bands-diamond"
import PrimaryButton from "../components/primary-button"
import AwardsVenueBackgroundImage from "../components/awards-venue-background-image"
import PaperlessAwardsVideo from "../components/paperless-awards-video"
import AwardSponsors from "../components/award-sponsors"
import HistoryTimeline from "../components/history-timeline"

const AwardsPage = () => (
  <Background>
    <Layout>
      <Seo title="Awards Ceremony" />
      <Container>
        <PageTitle text="Awards Ceremony" />
      </Container>
      <br /><br />
        <Container>
          <div className="row" style={{
            position: `relative`
          }}>
            <div className="col-lg" style={{
              background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
              padding: `3rem`
            }}>
              <div>
                <h4 style={{
                  display: `inline`,
                  backgroundColor: `#d23464`,
                  padding: `.5rem 0`,
                  lineHeight: `3.4rem`,
                  // boxShadow: `1.2rem 0 0 #d23464, -1.2rem 0 0 #d23464`,
                  textTransform: `uppercase`,
                  color: `#fff`,
                  fontFamily: `Avenir`,
                  fontWeight: `900`,
                  boxDecorationBreak: `clone`
                }}><span style={{padding: `1rem`}}>The Vox Conference Centre</span><br /><small style={{padding: `1rem`}}>21st November 2024 - Birmingham</small></h4>
              </div>
              {/* <p style={{
                color: `#fff`,
                fontFamily: `Din Pro`,
                marginTop: `1.5rem`
              }}>Below are the Special Recognition Awards. The Special Recognition Awards are different from the awards mentioned above, with one main distinction: your organisation or team cannot submit an entry to be considered for the Special Recognition awards – they can only be nominated by our expert judges.</p> */}
              <h5 style={{
                color: `#fff`,
                fontFamily: `Din Pro`,
                marginTop: `1.5rem`,
              }}>Address</h5>
              <p style={{
                color: `#fff`,
                fontFamily: `Din Pro`,
                marginTop: `1.5rem`
              }}>The Vox Conference Venue, Resort World, Marston Green, Birmingham B40 1PU</p>
              <h5 style={{
                color: `#fff`,
                fontFamily: `Din Pro`,
                marginTop: `1.5rem`,
                // textTransform: `uppercase  `
              }}>The Agenda</h5>
              <p style={{
                color: `#fff`,
                fontFamily: `Din Pro`,
                marginTop: `1.5rem`
              }}>
              18:00 - Venue Open &amp; Drinks Reception<br />
              19:00 - Welcome Address &amp; Dinner<br />
              21:00 - Awards Ceremony <small>(Hosted by one of the country's finest comedians)</small><br />
              22:00 - Live Band, Entertainment &amp; Networking<br />
              00:00 - Venue Close &amp; Carriages<br />
              </p>
            </div>
            <AwardsVenueBackgroundImage className="col-lg">
              <div style={{
                background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                width: `10rem`,
                height: `10rem`,
                borderRadius: `50%`,
                position: `absolute`,
                bottom: `1.5rem`,
                right: `-5rem`
              }} />
              <BandsCircle style={{ position: `absolute`, top: `50%`, right: `-16rem`, marginTop: `-9rem`}} size="20rem" />
              <BandsDiamond type="fine" style={{ position: `absolute`, left: `-2.5rem`, top: `-2.5rem`}} />
            </AwardsVenueBackgroundImage>
          </div>
        </Container>

        <Container>
          <div style={{
            // maxWidth: `968px`,
            margin: `5rem auto`
          }}>
            <div className="my-5">
              <PaperlessAwardsVideo title="What to Expect?" />
            </div>
          </div>
        </Container>

        <h3 style={{
          color: `#fff`,
          fontFamily: `Avenir`,
          fontWeight: `900`,
          fontSize: `2.5rem`,
          textAlign: `center`,
          margin: `3rem 0`
        }}>Shortlist</h3>

        <Container>
        <div className="row" style={{
          fontFamily: `Din Pro`,
          fontWeight: `400`,
          fontSize: `1rem`,
          color: `#fff`,
          background: `linear-gradient(90deg, rgba(210,52,100,.9) 0%, rgba(251,146,1,.9) 100%)`
        }}>
          <Container>
            <div style={{ padding: `2rem`}}>
              <h5>2024 Nominations</h5>
              <p>Thank you to all the organisations that submitted nominations for the 2024 awards. The entry period is now closed, and our judges are currently reviewing the submissions. The shortlist will be revealed on September 18th at the Smarter Working Live Conference in Birmingham and is free to attend for Public Sector. The shortlist will be published for general release on the website Monday 23rd September.</p>
              <PrimaryButton url="https://events.ringcentral.com/events/smarter-working-live-2024/registration" text="Get Your Conference Ticket" />
            </div>
          </Container>
        </div>
        </Container>


        <div>
        <Container>
          <PageTitle text="Book Your Place" />
          <Row>
            {/* <div className="col-md-6 offset-md-2"> */}
              <p style={{
                textTransform: `uppercase`,
                color: `#fff`,
                fontWeight: `bold`,
                textAlign: `center`,
                maxWidth: `850px`,
                margin: `auto`,
                marginBottom: `65px`
              }}>To book your place at Smarter Working Live 2024 Awards Ceremony, please complete the form below and a member of our team will be in touch to confirm your booking.</p>
            {/* </div>
            <div className="col-3 col-md-3">
              <PartnerCard title="Registration Partner" width="12rem" style={{ marginLeft: `1rem`, marginBottom: `4rem` }}>
                <OutboundLink href="https://www.lakesidesoftware.com/" target="_blank" rel="noopener noreferrer"><div style={{padding: `2rem 1.5rem`}}><LakesideSoftwareLogoImage /></div></OutboundLink>
              </PartnerCard>
            </div> */}
          </Row>


          <GridLines gridColor="#d23464">
            <div style={{
              fontFamily: `Din Pro`,
              // fontWeight: `400`,
              // fontSize: `1.5rem`,
              // textAlign: `center`,
              color: `#fff`,
              background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
              padding: `2rem`,
              margin: `0`
            }}>
              <form
                id="registration"
                name="registration"
                method="post"
                action="/register-thankyou"
                onSubmit={(e) => {
                  e.preventDefault()
                  let myForm = document.getElementById('registration')
                  let formData = new FormData(myForm)

                  fetch('https://hook.eu1.make.com/91l608ahj8crey3e6x5vy9542a344ji3', {
                    method: 'POST',
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: new URLSearchParams(formData).toString()
                  }).then(() => { 
                    // console.log('Form successfully submitted')
                    // download(`/downloads/Smarter_Working_Live_2021_Entry_Form.docx`)
                    // document.getElementById('formstatus').innerHTML = '<p>Thank you for registering and your entry form is downloading.</p>'
                    // if(window.dataLayer) {
                    //   window.dataLayer.push({event: 'form-registration-download', 'gtm.elementId': 'entries', 'gtm.elementUrl': undefined});
                    // }
                    window.location.replace(document.getElementById('registration').action)
                  }).catch((error) => alert(error))
                }}
              >
                {/* <input type="hidden" name="form-name" value="registration" /> */}
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      {/* <label for="FirstName">First Name</label> */}
                      <input type="text" className="form-control" id="FirstName" name="first_name" placeholder="First Name" required />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      {/* <label for="LastName">Last Name</label> */}
                      <input type="text" className="form-control" id="LastName" name="last_name" placeholder="Last Name" required />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      {/* <label for="Organisation">Organisation</label> */}
                      <input type="text" className="form-control" id="Organisation" name="organisation" placeholder="Organisation" required />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      {/* <label for="JobTitle">Job Title</label> */}
                      <input type="text" className="form-control" id="JobTitle" name="job_title" placeholder="Job Title" required />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      {/* <label for="Phone">Phone</label> */}
                      <input type="text" className="form-control" id="Phone" name="phone" placeholder="Phone" required />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      {/* <label for="Email">Email</label> */}
                      <input type="text" className="form-control" id="Email" name="email" placeholder="Email" required />
                    </div>
                  </div>
                </div>
                <p>
                  <strong>Select Your Package:</strong><br />
                  <span style={{ fontSize: `13px`}}>Drinks Reception and Networking, Three Course Dinner inc Alcohol/Soft Drinks, Awards Ceremony, Photograph on Arrival and Quality Live Entertainment</span>
                </p>
                <Packages />
                <br />
                <button type="submit" className="btn btn-primary" style={{
                  backgroundColor: `rgb(255, 255, 255)`,
                  borderColor: `rgb(255, 255, 255)`,
                  color: `rgb(210, 52, 100)`,
                  textTransform: `uppercase`,
                  borderRadius: 0,
                  padding: `0.6rem 1rem`,
                  fontWeight: 600,
                }}>Book Your Place</button>
              </form>
            </div>
          </GridLines>

          <br /><br />

          <div className="text-center">
            <PrimaryButton url="https://events.ringcentral.com/events/smarter-working-live-2024/registration" text="Looking for the Conference?" />
          </div>

          <AwardSponsors />

          <HistoryTimeline />

          <br /><br /><br />
      </Container>
      </div>
      <br />
      <br />
      <br />
    </Layout>
  </Background>
)

export default AwardsPage
